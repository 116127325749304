<template>
  <p>
    <b-icon
      :icon="icon.icon"
      :type="icon.type"
      :pack="icon.pack"
      size="is-small"
      class="has-margin-right-25"
    />
    <span v-text="event.text" />
  </p>
</template>

<script>
import { events } from "@src/services/log";
export default {
  name: "LogEvent",
  props: {
    log: {
      type: Object,
      required: true
    }
  },
  computed: {
    event() {
      const log = this.log;
      if (!this.$_.has(events, log.event))
        return { text: log.event, type: `info` };
      return events[log.event](log.metadata, log.request);
    },
    icon() {
      switch (this.event.type) {
        case "error":
          return {
            icon: "exclamation-circle",
            type: "is-danger",
            pack: "fas"
          };
        case "trash":
          return {
            icon: "trash-alt",
            type: "is-danger",
            pack: "far"
          };
        case "user":
          return {
            icon: "user-circle",
            type: "is-dark",
            pack: "fas"
          };
        case "success":
          return {
            icon: "check-circle",
            type: "is-success",
            pack: "fas"
          };
        case "secure":
          return {
            icon: "lock",
            type: "is-warning",
            pack: "fas"
          };
        case "cron":
          return {
            icon: "clock",
            type: "is-dark",
            pack: "far"
          };
        default:
          return {
            icon: "info-circle",
            type: "is-info",
            pack: "fas"
          };
      }
    }
  }
};
</script>
